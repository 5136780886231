.hide-cell {
  display: none;
}
body {
  margin: 0px;
}
.f-16 {
  font-size: 16px;
}
.view-button:hover .view-button-inner {
  color: orange;
  display: block;
  padding: 20px 5px;
}
.view-poi .customChooseImage {
  display: none;
}
.view-button .view-button-inner {
  display: none;
}
#___reactour .eWKATi {
  opacity: 0.7;
}
.view-button-inner {
  display: none;
}
.view-button:hover .view-button-inner {
  display: flex;
  /* background-color: #f7f9fc; */
}
.sidebarSmall .MuiListItemText-root {
  display: none;
}
.sidebarSmall svg[data-testid="ExpandMoreIcon"] {
  display: none;
}
div[data-rbd-droppable-id="individual_items"],
div[data-rbd-droppable-id="merged_group"] {
  overflow-anchor: none;
  background: #376fd01a;
  height: 250px;
  overflow-y: auto;
  margin-top: 15px;
  padding: 8px;
}
.pac-container {
  z-index: 9999;
}
.MuiToolbar-regular {
  padding-left: 16px;
  padding-right: 16px;
}
.MuiLink-root {
  cursor: pointer;
}
div[data-rbd-droppable-id="individual_items"] .MuiButtonBase-root,
div[data-rbd-droppable-id="merged_group"] .MuiButtonBase-root {
  border: 1px solid #7c7c7c40;
  padding: 5px 15px;
  margin-bottom: 8px;
}
label[for="raised-button-file"] {
  width: fit-content;
}
.MuiChip-label {
  white-space: normal;
}
.MuiTableCell-root .MuiButtonBase-root:hover,
.MuiTableCell-root .MuiButtonBase-root:focus {
  color: unset !important;
}
.MuiTableHead-root .MuiTableCell-root {
  white-space: nowrap;
}
th.MuiTableCell-root .MuiButtonBase-root {
  cursor: default;
}
th.MuiTableCell-root .MuiButtonBase-root svg {
  cursor: pointer;
}
#image-from-group .MuiFormControl-root img {
  max-width: 160px;
  margin-right: auto;
  margin-left: 0;
}
.MuiAutocomplete-inputRoot .MuiChip-filledDefault {
  margin-right: 5px;
}
.MuiTooltip-tooltip,
.MuiTooltip-tooltip p {
  font-size: 11.5px !important;
}
.MuiList-root[aria-labelledby="demo-simple-select-label"],
.MuiList-root[aria-labelledby="mui-15-label"],
.MuiList-root[aria-labelledby="timezone-label"],
.MuiList-root[aria-labelledby="map-orientation"],
.MuiList-root[aria-labelledby="mui-34-label"],
.MuiList-root[aria-labelledby="user-other-info"],
.MuiList-root[aria-labelledby="security"],
.MuiList-root[aria-labelledby="department"],
.MuiList-root[aria-labelledby="position-select"],
.MuiList-root[aria-labelledby="v-year"],
.MuiList-root[aria-labelledby="v-model"],
.MuiList-root[aria-labelledby="v-make"],
.MuiList-root[aria-labelledby="v-model"],
.MuiList-root[aria-labelledby="v-security-level"],
.MuiList-root[aria-labelledby="v-company"],
.MuiList-root[aria-labelledby="escort"],
.MuiList-root[aria-labelledby="v-status"],
.MuiList-root[aria-labelledby="v-type"],
.MuiList-root[aria-labelledby="host-dept"],
.MuiList-root[aria-labelledby="position"],
.MuiList-root[aria-labelledby="item-category"],
.MuiList-root[aria-labelledby="estimated"],
.MuiList-root[aria-labelledby="sposition"],
.MuiList-root[aria-labelledby="serviceLength"],
.MuiList-root[aria-labelledby="copy-tour"],
.MuiList-root[aria-labelledby="copy-task"],
.MuiList-root[aria-labelledBy="month"],
.MuiList-root[aria-labelledBy="main-list"] {
  max-height: 180px;
  overflow-y: auto;
}
.MuiList-root[aria-labelledby="storage-location"] {
  width: 300px;
}
/* Emergency procedures in logs module */
.addEmergency-product {
  position: relative;
}
.overlay-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  text-decoration: none !important;
}
.rc,
.rc_root {
  width: 100% !important;
}
.rc_root {
  border: none !important;
}
.rc_body-days-of-month .rc_body-cell .rc_body-cell_value:hover {
  background-color: #2196f3 !important;
  color: white !important;
}
.kHccWI {
  padding: 18px !important;
}
.YMDdL {
  padding-top: 0px !important;
}
.rc_body-days-of-month .rc_body-cell.rc_highlight:not(.rc_disabled) button,
.rc_body-days-of-month .rc_body-cell.rc_today:not(.rc_disabled) button {
  background-color: #2196f3 !important;
  color: white !important;
}
.addEmergency-product:hover .overlay-link {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 35px;
  background: #0000008c;
  color: #ffffff;
  border-radius: 10px;
  font-weight: 300;
  flex-direction: column-reverse;
}
.addEmergency-product:hover .overlay-link svg {
  width: 3.5em;
  height: 3.5em;
}
.signatureCanvas {
  padding: 5px;
  margin-top: 15px;
  height: 180px;
}
.signatureCanvas img {
  height: 100%;
  object-fit: contain;
  margin: 0 !important;
}
/* .signatureCanvas canvas {
  width: 100%;
} */
.signatureCanvas canvas {
  width: 100%;
  height: 150px;
}
/* Toggle Switch */
.switch {
  position: relative;
  display: inline-block;
  width: 47px;
  /* Adjust width as necessary */
  height: 21px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}
.jdDUUJ {
  color: #bdbdbd !important;
}
.qr-verify {
  max-width: 463px;
}
.poi-img .big-avatar {
  width: 145px !important;
}
.fFcQAn .MuiPaper-root {
  box-shadow: rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px,
    rgb(0 0 0 / 3%) 0px 0px 8px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px !important;
}
.gGupSZ {
  color: #ccc4c4;
}
.qmFyf {
  color: #ccc4c4;
}
/* .hvtUG .MuiPaper-root {
  box-shadow: rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px,
    rgb(0 0 0 / 3%) 0px 0px 8px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px !important;
} */

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.label {
  position: absolute;
  color: white;
  font-size: 10px;
  margin-top: 3px;
  /* Adjust font size as necessary */
}

.label:last-of-type {
  right: 2px;
  /* Adjust for proper positioning */
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
/* Emergency procedures in logd module */

/* Toolbar css */
.searchTool {
  max-width: 500px;
  flex: 0 0 500px;
  width: 100%;
  padding: 0 15px;
}
.searchTool .MuiIconButton-root {
  padding: 8px !important;
  width: auto !important;
  height: auto !important;
}
.searchTool .MuiFormControl-root {
  width: 100%;
}
.searchTool .MuiButtonBase-root {
  width: 60px;
  padding-left: 5px;
  padding-right: 5px;
  height: 52px;
}

/* Sticky table head */
.tableContainer {
  max-height: 400px;
}
.tableContainer::-webkit-scrollbar {
  resize: vertical;
  width: 0;
}
.tableContainer th {
  padding-top: 15px;
  padding-bottom: 15px;
}
.verification-links:hover {
  text-decoration: underline !important;
}

/*Ably toaster*/
.css-nvf14r-ToastContainer {
  margin-top: 40px;
  z-index: 9999 !important;
}

@media (max-width: 1200px) {
  .searchTool {
    max-width: 300px;
    flex: 0 0 300px;
  }
}
@media (max-width: 991px) {
  .MuiToolbar-root {
    flex-wrap: wrap;
    padding-top: 10px;
  }

  .searchTool {
    max-width: 100%;
    flex: 0 0 100%;
    padding: 0;
  }

  .MuiToolbar-root .MuiTextField-root {
    width: 100%;
  }
  #search-form .MuiMenu-paper {
    width: 100%;
    margin-left: 0;
    height: 340px;
  }
  #search-form .MuiList-root {
    min-width: 100%;
  }
  .MuiToolbar-root .fiirft {
    width: 100%;
  }
  .MuiToolbar-root .MuiInputAdornment-root {
    position: absolute;
    right: 0;
  }

  .MuiToolbar-root div:last-child .MuiPaper-elevation {
    position: absolute;
    right: 15px;
    top: 10px;
  }

  .MuiTablePagination-root .MuiToolbar-root {
    display: flex;
  }
}
@media (max-width: 575px) {
  header .MuiButtonBase-root {
    padding: 8px;
  }
  .MuiToolbar-root .totalRecord {
    order: 8;
  }
  .MuiToolbar-root .totalRecord .MuiTypography-root {
    margin-left: 0 !important;
  }
}

.Ip-List {
  display: block;
}

@media (max-width: 768px) {
  .searchTool {
    max-width: 100%;
    flex: 0 0 100%;
    padding: 0;
  }
  .map_filter_card {
    overflow-x: hidden;
  }
  .map_filter_card::-webkit-scrollbar {
    width: 2px;
  }
  .checkbox_popup_model {
    top: 321px;
    left: 18px;
    right: 18px;
    min-width: 0px;
  }
  .updated_heading {
    font-size: 11px;
  }
  .equipment_action_list {
    width: 250px;
  }
}

/* .bWmnhq:hover {
  background-color: #f7f9fc;
} */

.map_filter_card::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bdbdbd;
  border-radius: 10px;
}

.calendar_days:hover {
  background-color: rgba(255, 153, 0, 0.068);
}
.print-btn:hover {
  background-color: #2196f3;
  color: white;
}

.carousel.carousel-slider .control-arrow {
  background-color: grey !important;
}
