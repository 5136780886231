.wrapper {
  max-width: 1350px;
  padding: 0px 60px;
  margin: 50px auto;
  display: flex;
  flex-wrap: wrap;
  gap: 2em;
}

.wrapper img,
video,
audio {
  cursor: pointer;
}

/* modal */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(27, 27, 27, 0.541);
  display: flex;
  align-items: center;
  z-index: 9999999;
}
.overlay img,
video,
audio {
  display: block;
  max-width: 60%;
  max-height: 80%;
  margin: 60px auto;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
}
.overlay > span {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  color: #ffffff;
  z-index: 999;
  cursor: pointer;
}
.overlay-arrows_left {
  display: flex;
  background-color: #6d6d6d;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  width: 50px;
  height: 50px;
  left: 0;
  z-index: 999;
}

.overlay-arrows_left svg {
  width: 50px;
  height: 50px;
}

.overlay-arrows_right svg {
  width: 50px;
  height: 50px;
}

.overlay-arrows_right {
  display: flex;
  background-color: #6d6d6d;
  justify-content: space-between;
  position: absolute;
  right: 0;
  width: 50px;
  height: 50px;
  top: 50%;
  z-index: 999;
}
